"use client";

import React, {useEffect, useMemo} from "react";
import {UserContextProvider, useUserContext} from "@/lib/hooks/use-user-context";
import {useRouter} from "next/navigation";
import {Spinner} from "@/components/ui/spinner";

function Page() {

    const {user, tyrTokenRefresh} = useUserContext();

    const appBasePath = useMemo(() => {
        const basePath = process.env.NEXT_PUBLIC_APP_BASE_PATH;
        return basePath ? basePath + "/" : "/";
    }, [])

    useEffect(() => {
        if (!user) {
            setTimeout(async () => {
                if (!user) {
                    const refreshed = await tyrTokenRefresh();
                    if (!refreshed) {
                        console.log("unable to refresh token, redirecting to auth");
                        // router.replace("/auth");
                        location.href = appBasePath + "auth"; // use client side redirect rather than next/router
                        return;
                    }
                }
                console.log("redirecting to dashboard");
                location.href = appBasePath + "projects";
            }, 1000);
        } else {
            location.href = appBasePath + "projects";
        }
    }, [appBasePath, tyrTokenRefresh, user]);


    return <div className={"flex justify-center w-[100vw]"}>
        <div className={"content-center h-[100vh]"}>
            <div className={"grid grid-cols-[25px_1fr]"}>
                <Spinner className={"mr-2"}/>
                Authenticating user...
            </div>
        </div>
    </div>
}

export default function RedirectingPageWithContexts() {
    return <>
        <Page/>
    </>
}
